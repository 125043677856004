<template>
  <div>
    <Banner
      :title="cmbsBannerData.title"
      :description="cmbsBannerData.description"
      :imageName="cmbsDealXBannerData.backgroundImage"
      :height="cmbsDealXBannerData.bannerHeight"
      :showDealXBannerIcon="cmbsDealXBannerData.showDealXBannerIcon"
      :margin="cmbsDealXBannerData.margin"
      :rowHeight="cmbsDealXBannerData.rowHeight"
    />
    <Introduction />
    <Solution :featuresList="solutionData" />
    <Platform />
    <PoweredBy />
    <mds-layout-grid class="hero-banner">
      <mds-row>
        <mds-col>
          <div>
            <div class="title">{{ structuredBannerData.title }}</div>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import Banner from '../components/Common/Banner.vue';
import Platform from '../components/CreditAnalytics/CMBSData/Platform.vue';
import PoweredBy from '../components/CreditAnalytics/PoweredBy.vue';
import cmbsData from "@/data/cmbsData.json";
import Introduction from '../components/CreditAnalytics/CMBSData/Introduction.vue';
import Solution from '../components/CreditAnalytics/CMBSData/Solution.vue';
import BannerData from '@/data/banner.json';

export default {
  name: "CMBSData",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    Banner,
    Platform,
    PoweredBy,
    Introduction,
    Solution,
  },
  created() {
    this.saveSelectedProducts(['cmbs_data']);
  },
  mounted() {
    this.$contactType = "cmbs_data";
    this.saveShowSignInButton(true);
    this.saveActiveSignInLink(process.env.VUE_APP_ANALYTICS_SIGNIN_URL);
  },
  destroyed() {
    this.$contactType = "common";
    this.saveShowSignInButton(false);
  },
  data() {
    return {
      solutionData: cmbsData?.solution,
      cmbsBannerData: BannerData.cmbsDataBanner,
      cmbsDealXBannerData: BannerData.dealXBanner,
      structuredBannerData: BannerData.structuredBanner,
    };
  },
  methods: {
    ...mapActions({
      saveActiveSignInLink: 'signIn/setActiveSignInLink',
      saveShowSignInButton: 'signIn/setSignInVisibility',
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
  }
};
</script>

<style lang="scss" scoped>
.hero-banner {
    box-sizing: border-box;
    background-image: url("../../src/assets/analytics-bottom-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 159px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-banner .title {
    @include mds-level-3-heading($bold: true);
    color: $mds-color-white;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.03em;
    @media (max-width: $mds-breakpoint-m) {
      margin-left: 12px;
    }
  }

  .bold-text {
    @include mds-level-2-heading($bold: true);
  }
</style>
